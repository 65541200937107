body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #000;
  padding: 5%;
}

.app {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 20px;
}

.card {
  display: flex;
  align-items: center;
  background-color: #333;
  padding: 20px;
  text-decoration: none;
  color: #fff;
  border-radius: 4px;
  border: 1px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.card:hover {
  background-color: #064273;
  transform: translateY(-4px);
}

/* .card {
  display: flex;
  align-items: center;
  background-color: #000;
  color: #fff;
  padding: 10px;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
}

.card:hover {
  border-color: #064273;
} */

.card-image {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.card-title {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .app {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media screen and (max-width: 400px) {
  .app {
    grid-template-columns: repeat(1, 1fr);
  }
}


.header {
  color: #fff;
  width: 100%;
  text-align: center;
  /* margin-bottom: 20px; */
  padding-bottom: 1%;
}

.header-title {
  color: #fff;
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 20px;
  padding-left: 40px;
}

.greeting {
  color: #fff;
  font-size: 16px;
  text-align: left;
  padding-left: 40px;
}
